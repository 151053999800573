.main-list
{
    display: none;
    position: absolute;
    z-index: 1000;
}

.sub-list
{
    display: none;
    position: absolute;
    transform: translateX(100%);
    right: 0;
    top: 0;
}

.float-right {
    float: right!important;
}

.list-group-item.active {
    border-color: none;
}

.list-group-item
{
    margin: 0 2px;
    margin-top: -1px;
    cursor: pointer;
    width: 200px;
    color: #314397;
    text-shadow: none;
}